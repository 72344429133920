import { useState, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { cn } from '../../utils/cn-merge'
import useClickOutside from '../../hooks/useClickOutside'

import { ReactComponent as Logo } from '../../images/logo.svg'
import { Menu } from 'lucide-react'
import { HashLink } from 'react-router-hash-link'

const Header = () => {
  const location = useLocation()

  const [menuOpened, setMenuOpened] = useState(false)
  const toggleMenu = () => setMenuOpened(!menuOpened)
  const closeMenu = () => setMenuOpened(false)

  const ref = useRef(null)
  useClickOutside(ref, () => setMenuOpened(false))

  return (
    <div className='sticky top-10 z-50 w-full'>
      <div className='container'>
        <motion.div
          ref={ref}
          animate={{ height: menuOpened ? 385 : 70 }}
          transition={{ type: 'spring', duration: 0.5 }}
          className='flex flex-wrap items-center justify-between overflow-hidden rounded-2xl border border-zinc-600 bg-zinc-900/90 px-6 backdrop-blur lg:flex-nowrap'
        >
          <Link to={'/'} className='w-[120px]'>
            <Logo className='w-32 text-white' />
          </Link>
          <button onClick={toggleMenu} className='rounded-md p-2 transition-colors hover:bg-zinc-800 lg:hidden'>
            <Menu className='h-6 w-6 text-white' />
          </button>
          <nav className='flex w-full basis-full flex-col space-y-4 pb-4 text-lg text-white sm:items-center lg:flex-row lg:justify-end lg:space-x-4 lg:space-y-0 lg:pb-0 [&>.active]:text-primary'>
            <a
              href={'/#'}
              onClick={closeMenu}
              className={cn({ active: location.pathname === '' && location.hash === '' })}
            >
              Home
            </a>
            <HashLink to={'/#products'} onClick={closeMenu} className={cn({ active: location.hash === '#products' })}>
              Products
            </HashLink>
            <HashLink to={'/#banking'} onClick={closeMenu} className={cn({ active: location.hash === '#banking' })}>
              Banking
            </HashLink>
            <HashLink to={'/#aiclub'} onClick={closeMenu} className={cn({ active: location.hash === '#aiclub' })}>
              AI Club
            </HashLink>
            <HashLink to={'/#whyus'} onClick={closeMenu} className={cn({ active: location.hash === '#whyus' })}>
              Why Us
            </HashLink>
            <HashLink to={'/#experts'} onClick={closeMenu} className={cn({ active: location.hash === '#experts' })}>
              Experts
            </HashLink>
            <HashLink to={'/#home'} onClick={closeMenu} className={cn({ active: location.hash === '#joinwallet' })}>
              <button className='rounded-full bg-white px-6 py-1.5 text-base font-medium text-black transition-all hover:opacity-95'>
                Join Waitlist
              </button>
            </HashLink>
            {/*<Link to='/login'>*/}
            {/*  <button className='rounded-full bg-primary px-6 py-1.5 text-base font-medium transition-all hover:opacity-95'>*/}
            {/*    Signup / Login*/}
            {/*  </button>*/}
            {/*</Link>*/}
          </nav>
        </motion.div>
      </div>
    </div>
  )
}

export default Header

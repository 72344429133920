import { useEffect } from 'react'

function GoogleTranslate() {
  useEffect(() => {
    const googleTranslateScript = document.createElement('script')
    googleTranslateScript.type = 'text/javascript'
    googleTranslateScript.async = true
    googleTranslateScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    ;(document.getElementsByTagName('head')[0] || document.documentElement).appendChild(googleTranslateScript)
    window.googleTranslateElementInit = function () {
      new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element')
    }
  }, [])

  return (
    <div
      id='google_translate_element'
      className='sticky top-0 z-50 w-full bg-black/80 backdrop-blur [&>div]:flex [&>div]:w-full [&>div]:items-center [&>div]:justify-between [&>div]:px-4 [&>div]:py-1'
    />
  )
}

export default GoogleTranslate

import './css/index.css'

import { HashRouter as Router } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'

import { HomePage, TermsPage, PolicyPage, ImprintPage } from './pages'

function App() {
  return (
    <Routes>
      <Route path='*' element={<Navigate to={'/'} />}></Route>,
      <Route path='/' element={<HomePage />} />
      <Route path='/policy' element={<PolicyPage />} />
      <Route path='/terms' element={<TermsPage />} />
      <Route path='/imprint' element={<ImprintPage />} />
    </Routes>
  )
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  )
}

import Footer from '../landing/footer'
import Header from '../landing/header'

const Imprint = () => {
  return (
    <div className='flex flex-col bg-zinc-900 font-Open-Sans font-thin text-white'>
      <Header />
      <div className='container mt-32 flex min-h-[50vh] flex-col space-y-3'>
        <h1 className='mb-4 bg-gradient-to-r from-white via-zinc-200 to-zinc-800 bg-clip-text text-center text-4xl font-bold text-transparent sm:mb-8 sm:text-6xl'>
          Imprint
        </h1>
        <p className='text-base'>Capitavio Limited</p>
        <p className='text-base'>Terra Santa 10,</p>
        <p className='text-base'>Flat/Office 33,</p>
        <p className='text-base'>Strovolos 2001, Nicosia</p>
        <p className='text-base'>Cyprus</p>
        <p className='text-base'>
          Email:{' '}
          <a href='mailto:support@capitavio.com' className='text-primary'>
            support@capitavio.com
          </a>
          <br />
          Phone number:{' '}
          <a href='tel:+357 22 276 415' className='text-primary'>
            +357 22 276 415
          </a>
        </p>
        <p className='text-base'>
          Number: ΗΕ 390316
          <br />
          Sole director of Company is Jan Malkus
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default Imprint
